import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [



        {
            title: "Kalla",
            image: "https://i.imgur.com/r4e0S3ib.jpg",
            native: "Dienvidāfrika",
            description: "Senie Romieši atklāja kallu burvību un izmantoja to spilgto krāsu, lai izkošinātu ziemas vakarus. Tās mūsdienās izgaro ārpasaulīgu skaistumu.",
        },
        {
            title: "Lillija",
            image: "https://i.imgur.com/cRgewZrb.png",
            native: "Dienvidāfrika",
            description: "Daudzu svētku izvēlētākas puķes, lillijas izceļas ar savu dominanci. Lillijas ir toksiskas suņiem un kaķiem, tāpēc jāievēro īpaša piesardzība, ja mājā ir kustoņi.",
        },
        {
            title: "Lielā lauvmutīte",
            image: "https://i.imgur.com/TAwTe60b.jpg",
            native: "Eiropa",
            description: "Jau drīz, vasarā, šie ziedi ar dažadajām nokrāsām rotās daudzu dārzus vai terases, lauvmutīte ir īpaši piemērota augstu kompozīciju veidošanai.",
        },
        {
            title: "Roze",
            image: "https://i.imgur.com/v35ns2pb.jpg",
            native: "Āzija",
            description: "Rozes ir iesakņojušās mūsu pasaulē ar lielo popularitāti un dažādajām nozīmēm. Vai zināji, ka pasaulē ir vairāk par 25 000 rožu šķirņu?",
        },

        {
            title: "Alstromērija",
            image: "https://i.imgur.com/X1Pefc5b.jpg",
            description: "No Dienvidamerikas kalniem, tā ir atceļojusi uz daudzām audzētavām, jo pateicoties ilgajam mūzām vāzē (pat 14 dienas) un, protams, skaistajiem zidiem, tā ir populāra puķe ziedu kompozīcijās. ",
        },        
        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        },
        {
            title: "Leucadendron",
            image: "https://i.imgur.com/MieHPQfb.jpg",
            native: "Dienvidāfrika",
            description: "Kaut arī to galā škietami ir zieds, tās ir īpašas nokrāsas lapas. Dramatiskais izskats tam pieškir īpašu uzmanību. Šis augs vāzē dzīvos ilgi.",
        },

    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/a1b20366-58b0-4185-9bf4-b662067f87f9/playlist.m3u8"
                        captions="/titles/011.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Jā, mums arī pietrūkst saules</h2>

                            <p>Šoreiz majestātiska un saulaina kompozīcija. Ziedu saņemšanas brīdi dominēs 2 krāsas - zaļā un dzeltenā, bet pēc 2-3 dienām parādīsies vēl viena jauna krāsa - balta, jo atvērsies lillijas un alstromērijas. Pušķis var šķist mazāk “ziedošs”, pacieties mazliet, kad visas puķes izziedēs, tā būs liela un krāšņa kompozīcija. Ja mainīsi vāzē ūdeni ik 1-3 dienas, tad kompozīcija vāzē skaisti ziedēs pat līdz 14 dienām. </p>

                            <p><strong>UZMANĪBU:</strong> Ja mājās kaķi, tad nevajadzētu ļaut, lai tas smaržo, ēd lapas vai dzer ūdeni no vāzes, jo lillijas var kaitēt kaķu veselībai. Risinājumi - (1) lilliju likt atsevišķā vāzē un telpā, kur kaķis netiek ielaists; (2)  ja kaķis neizrāda interesi par puķēm - likt lilliju ziedu kompozīcijas vidū, lai tā būtu grūtāk “nodegustējama”. </p>

                            <p>Lūdzu dod mums ziņu, vai tev mājās ir kaķis, lai citām reizēm varam piemērot kompozīciju.</p>


                            <p>Pirms sāc:</p>

                            <ul>
                                <li>Šoreiz daudzi ziedi var būt pumpuros, dod tiem dažas dienas vāzē, lai tie pilnībā izplauktu.</li>
                                <li>Ziedi transportēšanas dēļ var izskatīties noliekušies. Tas nekas - atjauno griezuma vietu un ieliec tos vāzē ar ūdeni. Pēc dažām stundām tie atgūs savu formu.</li>
                                <li>Lillijas ir kaitīgas suņiem un kaķiem, izvērtē vai nepastāv risks, ka mājdzīvnieki tām var piekļūt.</li>
                            </ul>
                                

                            <p>Ķeramies pie darba:</p>

                            <ol>
                                <li>Seko video, lai redzētu darba procesu. </li>
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">Aizpildīt anketu</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/c0Ail6uh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Seko šīm norādēm, lai ziedi saglabātos ilgāk.</p>

                            <ol>
                                <li>Atceries ūdenī vāzē mainīt katru otro dienu</li>
                                <li>Izņem ziedus un noskalo ziedu kātus</li>
                                <li>Izlej ūdenu un izmazgā vāzi tīru</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <p>Īpaša norāde kallām - to kātos ir ļoti daudz ūdens, tiem nevajadzētu atrast pārāk dziļi ūdenī, savādāk tie var ātri sapūt. Ideālā gadījumā vāzē iepildi tikai dažus centimetrus ūdeni, to regulāri papildinot. </p>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
